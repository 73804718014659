import React from 'react';
import {Container, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
    container:{
        height:60,
        width:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginBottom:20,

        "& p":{
            fontSize:13.5,
            textAlign:"center"
        },

        "& a":{
            color:"#68F4A9",
            "&:hover":{
                color:"#68F4A9",
            }
        }
    }
})

function Footer(props) {
    
    const classes = useStyles();

    return (
        <div>
            <Container className={classes.container}>
                <Typography>
                Designed and developed by Momar TOURÉ. Built with&nbsp;
                <a href="https://www.gatsbyjs.com/" rel="noreferrer" target="_blank">Gatsby</a> 
                &nbsp;and hosted on&nbsp;
                <a href="https://firebase.google.com/" rel="noreferrer" target="_blank">Firebase</a>.
                </Typography>
               
            </Container>
        </div>
    );
}

export default Footer;
import React,{useState} from 'react';
import {Link} from 'gatsby';
import {AppBar, List, Button, Container, Drawer } from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import './../styles/hamburger.css';
import firebase from "gatsby-plugin-firebase"

const useStyles = makeStyles({
    navbar:{
        position:"fixed",
        height: 76,
        background: 'rgba(255, 255, 255, 0.12)',
        boxShadow:"0px 4px 8px rgba(27, 27, 27, 0.16)",
        backdropFilter: "blur(35px)",

        '@media (max-width:780px)': {
            height:65,
        },
        
    },
    container:{
        display:"flex",
        height:'100%',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        maxWidth:1168,
    },
    logo:{
        fontWeight:700,
        fontSize:20,
        "& span":{
            color: "#68F4A9",
        },
        "&:hover":{
            color:"#FFFFFF",
        }
    },
    list:{
        gap:20,
        display:"inline-flex",
        flexDirection:"row",
        justifyContent: "space-between",
        alignItems:"center",
        
        '@media (max-width:780px)': {
            display:"none",
        },
        
        "& a":{
            fontWeight:500
        },
        
        "& a:hover":{
            color: "#68F4A9",
        },
    },

    
    button:{
        width:147,
        background: 'rgba(255, 255, 255, 0.16)',
        boxShadow:"0px 4px 8px rgba(27, 27, 27, 0.16)",
        backdropFilter: "blur(16px)",
        borderRadius:16,
        height:48,
        transition:"all .1s ease-in-out",
        
        "&:hover":{
            transform: "scale(1.01)",
            background: 'rgba(255, 255, 255, 0.16)',
        },

        "& span":{
            fontWeight: 700,
            fontSize: 16,
            textTransform:"capitalize"
        },

    },
    hamburger:{
        display:"none",
        zIndex:10,
        margin:0,

        '@media (max-width:780px)': {
            display:"block",
            margin:0,
            "& span":{
                margin:0,
                color:"#FFFFFF"
            },
            
        },
    },
    drawer:{
        
        
        "& .MuiDrawer-paper":{
            position:"absolute",
            top:65,
            background: 'rgba(255, 255, 255, 0.12)',
            boxShadow:"0px 4px 8px rgba(27, 27, 27, 0.16)",
            backdropFilter: "blur(35px)",
            width:"70vw",
            display: "flex",
            flexDirection:"column",
            justifyContent:"space-around",
            gap:30,
        },

        '@media (min-width:780px)': {
            display:"none",
        },

      
    },
    list2:{
        width:"100%",
        marginBottom:50,
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"space-around",
        gap:50,

        "& a":{
            fontWeight:500,
            fontSize:19,
        },
        
        "& a:hover":{
            color: "#68F4A9",
        },
        "& span":{
            fontWeight: 700,
            fontSize: 19,
            textTransform:"capitalize"
        },
    },
    
    
})

function Navbar(props) {
    
    const classes = useStyles();

    //for the hamburger icon animation
    const [isActive, setActive] = useState("false");

    //for the drawer
    const [isOpen, setOpen] = useState(false);

    //handle hamburger animation
    const handleToggle = () => {
        setActive(!isActive);
        
    };

    //handle drawer opening
    const toggleDrawer = () => {

        setOpen(!isOpen);
      };

    const handleClick =() => {
        handleToggle();
        toggleDrawer();
    }
    const registerEvent = () => {
        firebase.analytics().logEvent("resume-downloaded")
    }
    
   
    return (
        <AppBar className={classes.navbar}>
            <Container className={classes.container}>
                <Link className={classes.logo} to ="/"> <span>&lt;/&gt;</span>  Momar</Link>
                <List className={classes.list}>
                    <Link to ="/blog" >Blog</Link>
                    <Link to ="/#work">Work</Link>
                    <Link to ="/#projects">Projects</Link>
                    <Link to ="/#contact">Contact</Link>
                    <a onClick={registerEvent} href="https://assets.ctfassets.net/nusiblwnfmfl/2Z8kpGlXjufgf0ybWLALjF/29ab3292ce3a26df2462dbfaa3e34e7e/Momar_Faly_TOUR__.pdf" target="_blank" rel="noreferrer">
                        <Button
                            variant="contained"
                            className={classes.button}
                            endIcon={<img src="/download.svg" alt="download-icon"/>}
                        >Resume</Button>
                    </a>
                     
                </List>
                
                <div onClick={handleClick} role="presentation" className={`${classes.hamburger} ${isActive ? "" : "open"}`} id="nav-icon1">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                

                
                <Drawer className={classes.drawer} anchor="right" open={isOpen} onClose={handleClick}>
                    <List className={classes.list2}>
                        <Link onClick={handleClick} to ="/blog" >Blog</Link>
                        <Link onClick={handleClick}  to ="/#work">Work</Link>
                        <Link onClick={handleClick} to ="/#projects">Projects</Link>
                        <Link onClick={handleClick} to ="/#contact">Contact</Link>
                        <a href="https://assets.ctfassets.net/nusiblwnfmfl/2Z8kpGlXjufgf0ybWLALjF/29ab3292ce3a26df2462dbfaa3e34e7e/Momar_Faly_TOUR__.pdf" target="_blank" rel="noreferrer">
                        <Button onClick={handleClick}
                            variant="contained"
                            className={classes.button}
                            style={{marginTop:30}}
                            endIcon={<img src="/download.svg" alt="download-icon"/>}
                        >Resume</Button>
                        </a>
                    </List>
                 </Drawer>
                 
            </Container>
            
        </AppBar>
    );
}

export default Navbar;